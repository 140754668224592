import { Component } from "fq-foundation/Scripts/Component"
new Component().create("Ratings", {
    element: ".m-star-rating",
    allowedDecimals: 1,
    init: function (el) {
        var self = this;
        el = el || $(self.element);
        $(el).each(function () {
            console.log("===============================================================")
            if (!$(this).is(".initialized")) {
                var rating = Number($(this).attr('data-rating'));
                if (rating > 0) {
                    $(this).addClass("initialized");
                    var fullStars = 0;
                    var extraStarPercent = 0;
                    var starWidth = 100, starCount = 5, spaceBetweenStars = 37.5;
                    var w = (starWidth * starCount) + (spaceBetweenStars * (starCount - 1))
                    var vb = "0 0 " + w + " " + starWidth;
                    $(this).find(".m-star-rating__wrapper>svg").attr("viewbox", vb);
                    $(this).find(".m-star-rating__wrapper>svg").attr("width", w);
                    if (("" + rating).indexOf(".") > -1) {
                        fullStars = ("" + rating).split(".")[0];
                        extraStarPercent = (("" + rating).split(".")[1]) * 10;
                    } else {
                        fullStars = rating;
                    }
                    $(this).find('.non-active path, .active rect').each(function () {
                        $(this).wrap("<svg viewbox='0 0 " + starWidth + " " + starWidth + "' width='" + starWidth + "' height='" + starWidth + "'/>")
                    });
                    $(this).find('.non-active svg, .active svg').each(function (i) {
                        var index = $(this).index();
                        $(this).attr("x", ((index)) * (starWidth + spaceBetweenStars));
                    });

                    $(this).find(".active svg").each(function (i, j, k) {
                        if (i < fullStars) {
                            $(this).find("rect").attr("width", starWidth);
                            $(this).find("rect").attr("height", starWidth);
                        } else if (i < fullStars + 1) {
                            $(this).find("rect").attr("width", extraStarPercent);
                            $(this).find("rect").attr("height", starWidth);
                        }
                    });
                }
            }

        });
    }
}); 