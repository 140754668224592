import "awesomplete";
import { Component } from "fq-foundation/Scripts/Component";
new Component().create("Smartystreets", {
    element: "[data-sc-field-name='streetaddress']",
    city: '[data-sc-field-name="city"]',
    state: '[data-sc-field-name="state"]',
    zip: '[data-sc-field-name="zip"]',
    data: {},
    focusout: function (ev, el) {
        var self = this;
        var thisZip = $(el);
        if (thisZip.val() != "") {
            $.ajax({
                url: "https://us-zipcode.api.smartystreets.com/lookup",
                type: "GET",
                datatype: 'jsonp',
                data: {
                    "key": "20464731093438589",
                    "zipcode": thisZip.val(),
                },
                success: function (data) {
                    var parsed = [];
                    for (var j = 0; j < data.length; j++) {
                        if (data[j] && data[j].zipcodes) {
                            for (var i = 0; i < data[j].zipcodes.length; i++) {
                                parsed.push(data[j].zipcodes[i].default_city)
                            }
                        }
                    }
                    $(thisZip).data("city_filter", parsed.join(","));
                },
            });
        };
    },
    selectComplete: function (ev, el) {
        var self = this;
        var dummy = $("<div/>").html($(el).val());
        var selected = $(dummy).text();
        var data = $(el).data("list");
        var f = data.suggestions.filter(function (x) {
            return selected == x.street_line + ", " + x.secondary + " (" + x.entries + " More Entries), " + x.city + ", " + x.state + ", " + x.zipcode;
        })[0];
        var selectedData = data.suggestions.filter(function (x) {
            return selected == x.street_line + ", " + (x.secondary != "" ? (x.secondary + ", ") : "") + x.city + ", " + x.state + ", " + x.zipcode || selected == x.street_line + ", " + x.secondary + " (" + x.entries + " More Entries), " + x.city + ", " + x.state + ", " + x.zipcode;
        })[0];
        $(el).closest("form").find(".smartystreets__flag").val(selectedData.street_line + (selectedData.secondary != "" ? "," + selectedData.secondary : ""));
        $(el).data("flag", selectedData.street_line + (selectedData.secondary != "" ? "," + selectedData.secondary : ""))
        $(el).val(selectedData.street_line + (selectedData.secondary != "" ? ", " + selectedData.secondary : ""));
        if (!!f) {
            $(el).data("selected", selected);
        }
        $(el).closest('form').find(self.state).val(selectedData.state).removeClass("is-hidden");
        $(el).closest('form').find(self.city).val(selectedData.city).removeClass("is-hidden");
        $(el).focus();
    },
    blur: function (e, el) {
        var self = this;
        if ($(el).data("flag") !== $(el).val()) {
            $(el).val("").removeData("selected");
            $(el).closest('form').find(self.state).val("").addClass("is-hidden");
            $(el).closest('form').find(self.city).val("").addClass("is-hidden");
            e.preventDefault();
        }
    },
    keydown: function (e, el) {
        var self = this;
        if ((e.keyCode == 38 || e.keyCode == 40) && $(el).next().is(":visible")) {
            var highlighted = $(el).next().find('li.highlighted');
            if ($(highlighted).length > 0) {
                if (e.keyCode == 40) {
                    var next = $(highlighted).removeClass("highlighted").next().length > 0 ? $(highlighted).removeClass("highlighted").next() : $(el).next().find('li:eq(0)');
                    $(next).addClass("highlighted").trigger("awesomeplete-customhighlight");
                } else if (e.keyCode == 38) {
                    var prev = $(highlighted).removeClass("highlighted").prev().length > 0 ? $(highlighted).removeClass("highlighted").prev() : $(el).next().find('li:last');
                    $(prev).addClass("highlighted").trigger("awesomeplete-customhighlight");
                }
            } else {
                $(el).next().find('li:eq(0)').addClass("highlighted").trigger("awesomeplete-customhighlight");
            }
        } else {
            if ($(el).val().length < 5) {
                $(el).removeData('selected');
            }
            self.search(el);
        }
    },
    beforeSetup: function (callback) {
        var self = this;
        $(self.city).attr("disabled", "disabled").attr("readonly", "readonly");
        $(self.state).attr("disabled", "disabled").attr("readonly", "readonly");
        callback();
    },
    submit: function (event, formElement) {
        return true;
    },
    beforeSetup: function (callback) {
        callback();
    },
    init: function () {
        var self = this;
        self.beforeSetup(function () {
            var el = $(self.element).get(0);
            $(el).closest('form').find(self.city).prop("readonly", true);
            $(el).closest('form').find(self.state).prop("readonly", true);
            $(el).closest('form').attr("autocomplete", "off");
            $(el).closest('form').on('submit', function (e) {
                if ($(this).find(".smartystreets__flag").val() === "false") {
                    e.preventDefault();
                    e.stopPropagation();
                    return false; // if you want to block submit
                } else {
                    $(this).find(self.state).removeClass("is-hidden").removeAttr("disabled").removeAttr("readonly");
                    $(this).find(self.city).removeClass("is-hidden").removeAttr("disabled").removeAttr("readonly");
                }
            });
            var zip = $(self.zip).get(0);
            $(zip).on('focusout', function (ev) {
                self.focusout(ev, $(this))
            });
            $(el).on('awesomplete-selectcomplete', function (ev) {
                self.selectComplete(ev, $(this));
            });
            $(el).on('blur', function (e) {
                self.blur(e, $(this))
            });
            $(el).on('keydown', function (e) {
                self.keydown(e, $(this));
            });
            if (window["Awesomplete"]) {
                var list = [];
                self.autoSuggestObject = new Awesomplete($(el).get(0), {
                    list: list,
                    minChars: 3,
                    maxItems: 10,
                    tabSelect: true
                });
            }
        });
    },
    search: function (el) {
        var self = this;
        if ($(el).val() != "" && $(el).val().length > 3) {
            var data = {
                "key": "20464731093438589",
                "max_results": 10,
                "search": $(el).val()
            }
            if ($(el).data('selected') != undefined) {
                data["selected"] = $(el).data('selected');
            } else {
                data["include_only_zip_codes"] = $(el).closest('form').find(self.zip).val();
            }
            $.ajax({
                url: "https://us-autocomplete-pro.api.smartystreets.com/lookup",
                type: "GET",
                datatype: 'jsonp',
                crossdomain: true,
                data: data,
                success: function (d) {
                    $(el).data("list", d);
                    self.s(d, el);
                },
                error: function (er) {
                    console.log(er);
                }
            });
        }
    },
    s: function (d, el) {
        var self = this;
        if (!!d.suggestions) {
            self.autoSuggestObject.list = d.suggestions.map(function (res) {
                if (res.entries > 1) {
                    return '<div class="more">' + res.street_line + ", " + res.secondary + " (" + res.entries + " More Entries), " + res.city + ", " + res.state + ", " + res.zipcode + '</div>'
                }
                return res.street_line + ", " + (res.secondary != "" ? (res.secondary + ", ") : "") + res.city + ", " + res.state + ", " + res.zipcode;
            });
            self.autoSuggestObject.evaluate();
            self.data.suggestions = d.suggestions;
        } else {
            $(el).closest('form').find(self.state).val("");
            $(el).closest('form').find(self.city).val("");
        }
    }
});
export default { Component, Awesomplete };