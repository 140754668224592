import { Component } from "fq-foundation/Scripts/Component"
import "fq-feature/Slider/src/Scripts/main";

new Component().create("Slider", {
    element: ".f-slider",
    sliderElement: ".f-slide__wrapper",
    init: function () {
        var self = this;
        $(this.element).each(function () {
            if ($(this).hasClass("f-slider--vertical")) {
                self.verticalSliderInit($(this), self);
            }
            else {
                self.sliderInit($(this), self);
            }
        });
    },
    sliderInit: function (el, self) {
        var curEl = $(el);
        var options = JSON.parse(JSON.stringify($(curEl).data('slick-settings')));
        options = (options === "") ? {} : options;
        options.appendDots = undefined;
        self.setArrowAndDotsColors(options, curEl);
        if ($(curEl).hasClass("f-slider--hotspot")) {
            options.appendDots = $(curEl).find('.f-slider__image--hotspot .f-slider__image-wrapper');
            options.dots = true;
        }
        $(curEl).find(self.sliderElement).on('init', function (event, slick) {
            if ($(this).closest(self.element).hasClass("f-slider--hotspot")) {
                if ($(curEl).closest(self.element).hasClass(self.element.replace('.', '') + "--hotspot")) {
                    self.setHotSpotLocations($(curEl).closest(self.element));
                    $(window).resize(function () {
                        setTimeout(function () {
                            self.setHotSpotLocations($(curEl).closest(self.element));
                        }, 100);
                    });
                }
            } else {
                // if($(curEl).closest(self.element).find('.f-slide > .f-quote').length > 0) {
                $(curEl).closest(self.element).find('.f-slide').css({
                    height: "100%"
                }).find('.columns').css({
                    height: "100%"
                });
                $(window).resize(function () {
                    setTimeout(function () {
                        $(curEl).closest(self.element).find('.f-slide').css({
                            height: "100%"
                        }).find('.columns').css({
                            height: "100%"
                        });
                    }, 100);
                });
                // }
            }

        });
        self.initialize($(curEl), $(curEl).find(self.sliderElement), options);
    },
    verticalSliderInit: function (el, self) {
        var curEl = $(el);
        var options = Object.assign({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            inifinite: false,
            swipe: false,
            appendDots: $(curEl).find('.vertical-dots'),
            customPaging: function (slider, slideIndex) {
                return "<span data-index=" + slideIndex + ">" + $(slider.$slides[slideIndex]).find(".f-slide").data("name") + "</span>";
            }
        }, JSON.parse(JSON.stringify($(curEl).data('slick-settings'))));
        self.setArrowAndDotsColors(options, curEl);
        $(curEl).find(self.sliderElement).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            if (!$(this).attr("initiator")) {
                $(this).attr("initiator", "true");
                $(this).closest(curEl).find('.f-slider__nav-wrapper .slide__wrapper').slick("slickGoTo", nextSlide);
            }
            $(curEl).find("[initiator]").removeAttr("initiator");
        });
        $(curEl).find('.f-slider__nav-wrapper .slide__wrapper').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            if (!$(this).attr("initiator")) {
                $(this).attr("initiator", "true");
                $(this).closest(curEl).find(self.sliderElement).slick("slickGoTo", nextSlide);
            }
            $(curEl).find("[initiator]").removeAttr("initiator");
        });
        self.initialize($(curEl), $(curEl).find(self.sliderElement), options);
        var dummySetting = Object.assign(options, {
            dots: false,
            infinite: false,
            swipe: false,
            arrows: false
        });
        $(curEl).find('.f-slider__nav-wrapper .slide__wrapper').slick(dummySetting);
    },
    setHotSpotLocations: function (scope, b, c) {
        var dot_positions = [];
        $(scope).find('.slick-slide:not(.slick-cloned) .f-slide').each(function () {
            dot_positions.push(typeof $(this).data('hotspot') === 'object' ? $(this).data('hotspot') : { x: 0, y: 0 })
        });
        $(scope).find('.f-slider__image--hotspot .f-slider__image-wrapper li').each(function (index, hotspot) {
            $(hotspot).css({
                top: dot_positions[index].y,
                left: dot_positions[index].x
            });
        });
    }
});