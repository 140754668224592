import { Component } from "fq-foundation/Scripts/Component"

new Component().create("LanguageSelector", {
    element: ".language-selector",
    init: function () {
        $(this.element).each(function () {
            $(this).find(".dropdown-menu a").each(function () {
                var url = new URL($(this).get(0).href);
                $(this).attr("href", url.pathname);
                $(this).text($(this).text().replace(/(.*)\(.*\)(.*)/gim, "$1$2"));
            });
            var selectedLanguage = $(this).find(".active").text();
            var togglebtn = $(this).find(">.dropdown > a.dropdown-toggle").text(selectedLanguage);
            $(togglebtn).on("click", function () {
                $(this).next(".dropdown-menu").toggle();
            });
        })
    }
});