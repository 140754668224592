import observeDOM from "./DomObserver.js";
import ElementExists from './element-exists';
import "./ieDetect";
import "./isArray";
import "./objectAssign";

window._componentList = {};
window["observeDOM"] = observeDOM;
window.$ = $;
window.jquery = $;
window.jQuery = $;

$(document).ready(function () {
	for(var i in window._componentList) {
		if( ElementExists(window._componentList[i].element) ) {
			window._componentList[i].init();
		}
	}
});

export class Component {
	constructor() {
		
	}
	create (name, options) {
		if(window._componentList[name] != undefined) {
			window._componentList[name] = Object.assign(window._componentList[name], options);
		} else {
			window._componentList[name] = options;
		}
		
		return this;
	}
}

export default Component;