import { Component } from "fq-foundation/Scripts/Component";
import "slick-carousel";

if (!!window.Promise && typeof window.Promise == "function") {
    var preload = function (src) {
        return new Promise(function (resolve, reject) {
            var img = new Image();
            img.onload = resolve
            img.onerror = reject
            img.src = src
        });
    }
    var preloadAll = function (srcs) {
        var promises = [];
        for (var i = 0; i < srcs.length; i++) {
            var p = preload(srcs[i]);
            promises.push(p)
        }
        return Promise.all(promises);
    }
}

new Component().create("Slider", {
    element: ".f-slider__wrapper",
    defaultOptions: {
        dots: true,
        arrows: true,
        mobileFirst: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 1
    },
    setArrowAndDotsColors: function (options, curEl) {
        if (options.arrows != undefined && options.arrows != "" && options.arrows != "null") {
            $(curEl).addClass("f-slider-arrows--" + options.arrows);
            options.arrows = true;
        } else {
            options.arrows = false;
        }
        if (options.dots != undefined && options.dots != "" && options.dots != "null") {
            $(curEl).addClass("f-slider-dots--" + options.dots)
            options.dots = true;
        } else {
            options.dots = false;
        }
        if (options.responsive != undefined) {
            for (var o in options.responsive) {
                switch (options.responsive[o].breakpoint) {
                    case 319:
                    case "319":
                        if (options.responsive[o].settings.arrows != "" &&
                            options.responsive[o].settings.arrows != null &&
                            options.responsive[o].settings.arrows != undefined &&
                            options.responsive[o].settings.arrows != false
                        ) {
                            $(curEl).addClass("f-slider-arrows--mobile-" + options.responsive[o].settings.arrows);
                            options.responsive[o].settings.arrows = true;
                        } else {
                            options.responsive[o].settings.arrows = false;
                        }
                        if (options.responsive[o].settings.dots != "" &&
                            options.responsive[o].settings.dots != null &&
                            options.responsive[o].settings.dots != undefined &&
                            options.responsive[o].settings.dots != false
                        ) {
                            $(curEl).addClass("f-slider-dots--mobile-" + options.responsive[o].settings.dots);
                            options.responsive[o].settings.dots = true;
                        } else {
                            options.responsive[o].settings.dots = false;
                        }
                        break;
                    case 767:
                    case "767":
                        if (options.responsive[o].settings.arrows != "" &&
                            options.responsive[o].settings.arrows != null &&
                            options.responsive[o].settings.arrows != undefined &&
                            options.responsive[o].settings.arrows != false
                        ) {
                            $(curEl).addClass("f-slider-arrows--tablet-" + options.responsive[o].settings.arrows);
                            options.responsive[o].settings.arrows = true;
                        } else {
                            options.responsive[o].settings.arrows = false;
                        }
                        if (options.responsive[o].settings.dots != "" &&
                            options.responsive[o].settings.dots != null &&
                            options.responsive[o].settings.dots != undefined &&
                            options.responsive[o].settings.dots != false
                        ) {
                            $(curEl).addClass("f-slider-dots--tablet-" + options.responsive[o].settings.dots);
                            options.responsive[o].settings.dots = true;
                        } else {
                            options.responsive[o].settings.dots = false;
                        }
                        break;
                    case 1023:
                    case "1023":
                        if (options.responsive[o].settings.arrows != "" &&
                            options.responsive[o].settings.arrows != null &&
                            options.responsive[o].settings.arrows != undefined &&
                            options.responsive[o].settings.arrows != false
                        ) {
                            $(curEl).addClass("f-slider-arrows--desktop-" + options.responsive[o].settings.arrows);
                            options.responsive[o].settings.arrows = true;
                        } else {
                            options.responsive[o].settings.arrows = false;
                        }
                        if (options.responsive[o].settings.dots != "" &&
                            options.responsive[o].settings.dots != null &&
                            options.responsive[o].settings.dots != undefined &&
                            options.responsive[o].settings.dots != false
                        ) {
                            $(curEl).addClass("f-slider-dots--desktop-" + options.responsive[o].settings.dots);
                            options.responsive[o].settings.dots = true;
                        } else {
                            options.responsive[o].settings.dots = false;
                        }
                        break;
                    case 1215:
                    case "1215":
                        if (options.responsive[o].settings.arrows != "" &&
                            options.responsive[o].settings.arrows != null &&
                            options.responsive[o].settings.arrows != undefined &&
                            options.responsive[o].settings.arrows != false
                        ) {
                            $(curEl).addClass("f-slider-arrows--widescreen-" + options.responsive[o].settings.arrows);
                            options.responsive[o].settings.arrows = true;
                        } else {
                            options.responsive[o].settings.arrows = false;
                        }
                        if (options.responsive[o].settings.dots != "" &&
                            options.responsive[o].settings.dots != null &&
                            options.responsive[o].settings.dots != undefined &&
                            options.responsive[o].settings.dots != false
                        ) {
                            $(curEl).addClass("f-slider-dots--widescreen-" + options.responsive[o].settings.dots);
                            options.responsive[o].settings.dots = true;
                        } else {
                            options.responsive[o].settings.dots = false;
                        }
                        break;
                    case 1407:
                    case "1407":
                        if (options.responsive[o].settings.arrows != "" &&
                            options.responsive[o].settings.arrows != null &&
                            options.responsive[o].settings.arrows != undefined &&
                            options.responsive[o].settings.arrows != false
                        ) {
                            $(curEl).addClass("f-slider-arrows--fullhd-" + options.responsive[o].settings.arrows);
                            options.responsive[o].settings.arrows = true;
                        } else {
                            options.responsive[o].settings.arrows = false;
                        }
                        if (options.responsive[o].settings.dots != "" &&
                            options.responsive[o].settings.dots != null &&
                            options.responsive[o].settings.dots != undefined &&
                            options.responsive[o].settings.dots != false
                        ) {
                            $(curEl).addClass("f-slider-dots--fullhd-" + options.responsive[o].settings.dots);
                            options.responsive[o].settings.dots = true;
                        } else {
                            options.responsive[o].settings.dots = false;
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    },
    initialize: function (parent, element, settings) {
        var self = this;
        var images = $(parent).find("img, picture source").map(function () {
            return $(this).attr("src") != undefined ? $(this).attr("src") : $(this).attr("srcset");
        });

        if (!!window.Promise && typeof window.Promise == "function") {
            preloadAll(images).then(function (l) {
                // console.log(l)
                self.createSlider(parent, element, settings)
            }, function (er) {
                console.log(er)
            });
        } else {
            self.createSlider(parent, element, settings)
        }
    },
    createSlider: function (parent, element, settings) {
        var self = this;
        if (settings == "") {
            settings = {};
        }
        var options = Object.assign(JSON.parse(JSON.stringify(self.defaultOptions)), settings);
        if (self['is-ee']) {
            self.initEE(parent, element);
        } else {
            $(window).resize(function () {
                setTimeout(() => {
                    $(element)[0].slick.refresh();
                }, 200);
            });
            $(element).slick(options).on('setPosition', function (event, slick) {
                $(element).find('.slick-slide').css('height', slick.$slideTrack.height() + 'px');
            }).on('beforeChange', function () {
                $(this).closest(".f-slider").trigger('slidechanging')
            });
        }
    },
    init: function (options) {
        if (options == undefined) {
            options = {};
        }
        this.initialize($(this.element).parent(), $(this.element), options);
    }
});
export default Component;