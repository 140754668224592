import { Component } from "fq-foundation/Scripts/Component"

new Component().create("SizingGuide", {
    element: ".size-guide-modal",
    filter: {
        maxWeight: 290,
        maxHeight: 9999,
        availableFor: ['Protective Underwear', 'Next Generation Briefs']
    },
    init: function () {
        var self = this;
        $('.size-wise-guide-modal').find(".continue-browsing").on('click', function (e) {
            e.preventDefault();
            $(this).closest(".modal").removeClass("is-active");
        });
        $(self.element).find(".modal-close").on('click', function () {
            $(this).closest(".modal").find("select").each(function () {
                var v = $(this).find("option:eq(0)").attr("value");
                $(this).val(v).trigger("change");
            });
        });
        $(self.element).find(".a-button").on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            // Validate Form having atleast 1 dropdown selected
            var count = 0;
            var targetEl = $(this);
            $(self.element).find("select").each(function () {
                if ($(this).find('option:selected').attr('value') != undefined && $(this).find('option:selected').attr('value') != "") {
                    count++;
                }
            });
            if (count == 4) {
                var height = $(self.element).find('select[name=height]').val();
                var weight = $(self.element).find('select[name=weight]').val();
                var gender = $(self.element).find('select[name=gender]').val();
                var style = $(self.element).find('select[name=style]').val();

                if (!!self.filter.maxWeight || !!self.filter.maxHeight || !!self.filter.availableFor) {
                    var maxWeight = self.filter.maxWeight;
                    var maxHeight = self.filter.maxHeight;

                    if (maxWeight >= Number(weight) && maxHeight >= Number(height)) {
                        var reqData = {
                            productStyle: style,
                            height: height,
                            weight: weight,
                            gender: gender,
                        };
                        $.post({
                            url: "/api/sitecore/SizeGuide/SizeWiseGuideCalculation",
                            data: reqData,
                            success: function (res) {
                                $(targetEl).closest(".modal").find("select").each(function () {
                                    var v = $(this).find("option:eq(0)").attr("value");
                                    $(this).val(v).trigger("change");
                                });
                                if (res.data) {
                                    $(targetEl).closest(".modal").removeClass("is-active");
                                    if (res.resultStatus) {
                                        $("#modal-size-wise-guide").find(".a-summary").html("We Recommend Size");
                                        $("#modal-size-wise-guide").find(".sizeicon").html(res.data.sizeIcon);
                                        $("#modal-size-wise-guide").find(".sizedes").html(res.data.sizeDesc);
                                    }
                                    $("#modal-size-wise-guide").trigger("openmodal");
                                } else {
                                    return false;
                                }
                            },
                            error: function (error) {
                                throw error;
                            }
                        });
                    } else {
                        $(targetEl).closest(".modal").removeClass("is-active");
                        $("#modal-size-wise-guide").find(".a-summary").empty().html(
                            "<p>Only Brief with Closure tabs is available at this size</p>");
                        $("#modal-size-wise-guide").trigger("openmodal");
                        $(targetEl).closest(".modal").find("select").each(function () {
                            var v = $(this).find("option:eq(0)").attr("value");
                            $(this).val(v).trigger("change");
                        });
                    }
                }
            }
        });
    }
});