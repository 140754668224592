import { Component } from "fq-foundation/Scripts/Component"

new Component().create("Modal", {
    element: ".f-modals .modal",
    permaCloseKey: window.location.hostname,
    autoCloseCheck: function () {
        var form = $(".f-modals .modal.is-active").find("form[data-sc-fxb]");
        var ajaxCallback = $(form).attr('data-ajax-success');

        $(form).attr('data-ajax-success', ajaxCallback + ';_componentList["Modal"]["autoCloseCheck"].call(this.getAttribute("data-sc-fxb"))');

        var modal = $(form).closest(".modal");
        var settings = $(modal).data('modal-settings') || {};
        var autoClose = (!!settings.autoClose && settings.autoClose != '') ? settings.autoClose.toString() : "";
        autoClose = autoClose.indexOf('|') > -1 ? autoClose.split("|")[0] : autoClose;

        $(modal).attr('data-step-count', Number($(modal).attr('data-step-count')) + 1);

        if ($(modal).attr('data-step-count') == $(modal).attr('data-close-step')) {
            if (autoClose != '') {
                setTimeout(function () {
                    $(modal).removeClass("is-active")
                }, Number(autoClose.replace("ms", "00").replace("s", "000")));
            }
        }
    },
    init: function () {
        var self = this;
        $(self.element).each(function () {
            var modal = $(this);
            var settings = $(this).data('modal-settings') || {};
            var modalEvent = settings.event;
            var delay = settings.delay;
            var autoClose = (!!settings.autoClose && settings.autoClose != '') ? settings.autoClose.toString() : "";
            var autoCloseStep = (!isNaN(settings.autoCloseStep)) ? Number(settings.autoCloseStep) : 1;
            var k = self.permaCloseKey + "--" + $(this).attr("id");
            if (autoClose.indexOf('|') > -1) {
                autoCloseStep = autoClose.split("|")[1];
                autoClose = autoClose.split("|")[0];
            }

            var closePermanently = settings["closePermanent"];

            $(this).attr('data-step-count', 1);
            $(this).attr('data-close-step', autoCloseStep);

            var ajaxCallback = $(this).find('form').attr('data-ajax-success');
            $(this).find('form').attr('data-ajax-success', ajaxCallback + ';_componentList["Modal"]["autoCloseCheck"].call(this.getAttribute("data-sc-fxb"))');

            $(this).on('openmodal', function (e) {
                var m = $(this);
                e.preventDefault();
                $(m).addClass('is-active');
                if (!!autoClose.trim()) {
                    if ($(m).attr('data-step-count') == $(m).attr('data-close-step')) {
                        if (autoClose != '') {
                            setTimeout(function () {
                                $(m).removeClass("is-active")
                            }, Number(autoClose.replace("ms", "00").replace("s", "000")));
                        }
                    }
                }
            });

            $(this).find(".modal-close").on('click', function () {
                if (closePermanently) {
                    window.localStorage.setItem(k, "true")
                }
                $(this).closest(self.element).data('closed', true);
                $(this).closest(self.element).removeClass('is-active');
            });

            if (modalEvent) {
                switch (modalEvent.toLowerCase().trim()) {
                    case "load":
                        setTimeout(function () {
                            var closedPermanently = window.localStorage.getItem(k) === "true";
                            if (!closedPermanently) {
                                $(modal).trigger("openmodal");
                            }
                        }, delay * 1000);
                        break;
                    case "scroll":
                        if ((window.scrollY / window.innerHeight) * 100 >= delay) {
                            var closedPermanently = window.localStorage.getItem(k) === "true";
                            if (!closedPermanently && !$(modal).data('closed')) {
                                $(modal).trigger("openmodal");
                            }
                        }
                        window.onscroll = function () {
                            if ((window.scrollY / window.innerHeight) * 100 >= delay) {
                                var closedPermanently = window.localStorage.getItem(k) === "true";
                                if (!closedPermanently && !$(modal).data('closed')) {
                                    $(modal).trigger("openmodal");
                                }
                            }
                        }
                        break;
                    default:
                        $("[data-modal='" + settings['trigger-el'] + "']").on("click", function (e) {
                            e.preventDefault();
                            var closedPermanently = window.localStorage.getItem(k) === "true";
                            if (!closedPermanently) {
                                $(modal).trigger("openmodal");
                            }
                        });
                        break;
                }
            }
        });
    }
});