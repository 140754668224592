import { Component } from 'fq-foundation/Scripts/Component'

new Component().create('ProductFilter', {
    element: '.f-product-finder',
    submit: '.f-product-finder .a-button',
    dropdowns: '.finder-wrapper select',
    init: function () {
        var self = this;
        self.gender = '';
        self.productType = '';
        self.leakageLevel = '';
        setTimeout(function () {
            self.bindFinderEvents(function () {
                $(self.dropdowns).each(function () {
                    $(this).val($(this).val()).trigger('change');
                });
            });
        }, 50)
    },
    bindFinderEvents: function (callback) {
        self = this;
        $(self.dropdowns).on('change', function () {
            var typeOfSelection = $(this).attr('name');
            if (typeOfSelection == 'gender') {
                self.gender = this.value || '';
            }
            if (typeOfSelection == 'productType') {
                self.productType = this.value || '';
            }
            if (typeOfSelection == 'leakageLevel') {
                self.leakageLevel = this.value || '';
            }
            if (
                self.gender != '' &&
                self.productType != '' &&
                self.leakageLevel != ''
            ) {
                $(self.submit).attr(
                    'href',
                    `/products/${self.gender}?producttype=${self.productType}&leakagetype=${self.leakageLevel}`,
                );
            } else {
                $(self.submit).attr('href', 'javascript:void(0)');
            }
        });
        callback();
    },
})
