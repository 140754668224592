import "awesomplete";
import { Component } from "fq-foundation/Scripts/Component"

new Component().create("Search", {
    element: ".f-search-box",
    loadValue: "",
    init: function () {
        var self = this;
        if ($(self.element).length > 0) {
            self.loadValue = $(self.element).find("input[type=search]").val();
            if ($(self.element).find("input[type=search]").val() != "" ) {
                $(self.element + " input[type=search]").closest('.f-search-box__wrapper').addClass("hasvalue");
            } else {
                $( self.element + " input[type=search]").closest('.f-search-box__wrapper').removeClass("hasvalue");
            }
            $(self.element).find("input[type=search]").on('keyup', function (ev) {
                if (ev.keyCode == 40) {
                    // down arrow
                } else if (ev.keyCode == 38) {
                    // up arrow
                } else {
                    if ($(this).val().trim() == "") {
                        $(this).closest('.f-search-box__wrapper').removeClass("hasvalue");
                    } else {
                        $(this).closest('.f-search-box__wrapper').addClass("hasvalue");
                    }
                    self.autoSuggest($(this).get(0));
                }
            });
            $(self.element).find("input[type=search]").on('change', function () {
                if ($(this).val().trim() == "") {
                    $(this).closest('.f-search-box__wrapper').removeClass("hasvalue");
                } else {
                    $(this).closest('.f-search-box__wrapper').addClass("hasvalue");
                }
            });
            $(self.element + " input[type=search]").on('awesomplete-selectcomplete', function () {
                $(this).closest('form').submit();
            });
            $(self.element).on('click', ".fa-times", function () {
                $(this).closest('.f-search-box__wrapper').removeClass("hasvalue").find("input[type=search]").val("");
                $(this).closest(self.element).find("input[type=search]").focus();
            });
            if (window["Awesomplete"]) {
                var list = [];
                self.autoSuggestObject = new Awesomplete($(self.element).find("input[type=search]").get(0), {
                    list: list,
                    minChars: 3,
                    maxItems: 8,
                    tabSelect: true
                });
            }
        }
    },
    autoSuggest: function (el) {
        self = this;
        $.ajax({
            type: "POST",
            url: "/api/feature/search/ajaxsearch",
            datatype: "application/json",
            data: {
                "Query": $(el).val()
            },
            success: function (data) {
                self.autoSuggestObject.list = data.Results.Results.map(function (res) {
                    return res.Title;
                });
                self.autoSuggestObject.evaluate()
            },
            error: function (er) {
                throw er;
            }
        });
    }
});