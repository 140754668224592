import { Component } from "fq-foundation/Scripts/Component";
import "fq-feature/Video/src/Scripts/main";
let videojs = window.videojs;

new Component().create("Video", {
    element: ".f-video",
    brightcove: true,
    createShadowFilter: function () {
        var shadowSVG = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style="display: none;">
        <defs>
            <filter id="shadow" x="0" y="0">
                <feOffset result="offOut" in="SourceAlpha" dx="2" dy="2" />
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="8" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
            </filter>
        </defs>
    </svg>`;
        $("footer").after(shadowSVG);

    },
    init: function () {
        var self = this;
        if (self["is-ee"] !== true) {
            var c = 0;
            self.createShadowFilter();
            setTimeout(() => {
                $(self.element).each(function () {
                    var videoItem = $(this);
                    var v = videojs.getPlayer($(this).find(".video-js").attr("id"));
                    if ($(v).attr("autoplay") !== undefined) {
                        $(v).attr("data-ap", true).removeAttr("autoplay");
                    }
                    v.on('canplaythrough', function () {
                        self.autoPlayVideo(v, $(videoItem).find(".video-js"));
                    });
                });
                self.preloadResponsiveImages(function () {
                    self.bindVariousEvents();
                });
            }, 300)
        }
    },
    preloadResponsiveImages: function (callback) {
        var self = this;
        var c = $(self.element).find(".f-responsive-image source, .f-responsive-image img").length;
        if (c <= 0) {
            callback();
        }
        $(self.element).find(".f-responsive-image source, .f-responsive-image img").each(function () {
            var i = new Image();
            i.onload = function () {
                c -= 1;
                if (c == 0) {
                    callback();
                }
            }
            i.onerror = function () {
                c -= 1;
                if (c == 0) {
                    callback();
                }
            }
            i.src = ($(this).attr("srcset") != undefined && $(this).attr("srcset") != "") ? $(this).attr("srcset") : $(this).attr("src");
        });
    },
    autoPlayVideo: function (v, video) {
        var self = this;
        console.log($(video), $(video).data("ap"))
        if ($(video).data("ap") === true) {
            try {
                var p = v.play();
                p.catch((er) => {
                    v.muted(true);
                    v.play();
                });
            } catch (er) {
                v.muted(true);
                v.play();
            }
        }
    },
    loadScript: function (url, callback) {
        var self = this;
        var script = document.createElement("script");
        script.type = "text/javascript";
        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState == "loaded" ||
                    script.readyState == "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = function () {
                callback();
            };
        }
        script.src = url;
        document.body.appendChild(script);
    },
    loadBrightCoveScript: function (el, callback) {
        var self = this;
        if (self.brightcove) {
            var account = $(el).attr("data-account");
            var player = $(el).attr("data-player");
            var url = "//players.brightcove.net/" + account + "/" + player + "_default/index.min.js";

            self.loadScript(url, callback);
        }
    },
    bindVariousEvents: function () {
        var self = this;
        $(self.element).each(function () {
            var v = $(this).find('video').get(0);
            if ($(this).hasClass('f-video--inline')) {
                if ($(this).find('.f-responsive-image').height() > 0) {
                    $(this).height($(this).find('.f-responsive-image').height());
                }
            }
            v.onplay = function () {
                $(this).closest(self.element).addClass("played-already");
                if ($(this).closest(self.element).hasClass('f-video--inline')) {
                    $(this).closest(self.element).find('.video-content').hide();
                    $(this).closest(self.element).find('.f-video__wrapper').show();
                } else {
                    $(this).closest(self.element).find('.video-content-wrapper').hide();
                    $(this).closest(self.element).find('.f-video__wrapper').show();
                }
                $(this).closest(".f-video--overlay").css({ 'z-index': 9999 });
            }
            v.onplaying = v.ontimeupdate = function () {
                $(this).closest(self.element).addClass("vjs-playing")
            }
            $(v).on('waiting pause', function () {
                $(this).closest(self.element).removeClass("vjs-playing");
            });

        });

        window.onresize = function () {
            $(self.element).each(function () {
                if ($(this).hasClass('f-video--inline')) {
                    if ($(this).find('.f-responsive-image').height() > 0) {
                        $(this).height($(this).find('.f-responsive-image').height());
                    } else {
                        var v = $(this).closest('.f-video').find("video").get(0);
                        var wasPlaying = $(this).hasClass("vjs-playing");
                        v.pause();
                        $(this).closest(self.element).find('.video-content').show();
                        $(this).closest(self.element).find('.video-content-wrapper').show();
                        $(this).height($(this).find('.f-responsive-image').height());
                        // $(this).closest(self.element).find('.f-video__wrapper').hide();
                        $(this).closest(self.element).find('.video-content-wrapper').hide();
                        $(this).closest(self.element).find('.video-content').hide();
                        if (!!wasPlaying) {
                            v.play();
                        }
                        // Play if was playing
                    }
                } else if ($(this).hasClass('f-video--background')) {

                }
            });
        }
        $(self.element).find('.f-video__close').on('click', function (e) {
            $(this).closest('.f-video').find('.f-video__wrapper').hide();
            // if ($(this).closest(self.element).hasClass('f-video--inline')) {
            $(this).closest(self.element).find('.video-content-wrapper').show();
            // }
            var v = $(this).closest('.f-video').find("video").get(0);
            v.pause();
            $(this).closest(".f-video--overlay").css({ 'z-index': 1 });
            e.preventDefault();
        });
        $(self.element).find('.video-content .f-video-trigger').on('click', function () {
            var v = $(this).closest('.f-video').find("video").get(0);
            v.play();
        });
    }
});

export default Component;