import { Component } from "fq-foundation/Scripts/Component"

new Component().create("Dialog", {
    element: ".f-dialog",
    otpSelector: ".f-dialog.f-otp-dialog",
    errorSelector: ".f-dialog.f-otp-dialog .error",
    parseCookie: function (str) {
        var self = this;
        var parsedCookie = {}
        var x = str.split(";");
        x.forEach(c => {
            var ck = {}
            var sliceIndex = c.indexOf("=")
            var key = c.slice(0, sliceIndex);
            var value = self.getObjectFromUrl(c.slice(sliceIndex + 1, c.length));
            parsedCookie[key.trim()] = value;
        });
        return parsedCookie;
    },
    getObjectFromUrl: function (str) {
        var b = str.trim();
        var arr = str.split("&")
        if (arr.length > 1) {
            arr.forEach(a => {
                if (typeof b === "string") {
                    b = {};
                }
                var x = a.split("=")
                b[x[0].trim()] = x[1].trim();
            });
        }
        return b;
    },
    init: function () {
        var self = this;
        self.resendLinkTO = null;
        var preventNext = [18, 8, 46, 9, 16, 17];
        $(self.otpSelector).find(".a-link").on("click", function (e) {
            e.preventDefault();
            $.ajax({
                type: "POST",
                url: $(this).data("href"),
                success: function (d) {
                    $(self.otpSelector).find(".a-link").hide();
                    self.resendLinkTO = setTimeout(function () { self.showResendLink(); }, 5000);
                },
                error: function (er) {
                    console.log(er);
                }
            });
        });

        $(self.otpSelector).on("keydown", function (e) {
            if (e.keyCode === 27) {
                e.preventDefault();
                return false;
            }
        });

        $(self.otpSelector).find('[name="txt-otp"]:nth-child(1)').focus();
        $(self.otpSelector).find('[name="txt-otp"]').on("paste", function (e) {
            setTimeout(function () {
                var v = $(e.target).val();
                $(self.otpSelector).find('[name="txt-otp"]').each(function (i, element) {
                    $(element).val(v[i]).trigger("keyup");
                });
            }, 10)
        })
        $(self.otpSelector).find('[name="txt-otp"]').on("keydown", function (e) {
            if (preventNext.indexOf(e.keyCode) > -1 || (e.keyCode > 65 && e.keyCode < 90)) {

            } else {
                if ($(this).val().length > 0) {
                    $(this).next().focus();
                }
            }
        });
        $(self.otpSelector).find('[name="txt-otp"]').on("keyup", function (e) {
            if (e.keyCode == 8) {
                $(this).prev().focus();
            }
            if ($(this).is(":nth-child(6)")) {
                var value = "";
                $(".f-otp-dialog [name=txt-otp]").each((a, b) => {
                    value += $(b).val();
                });
                $.ajax({
                    type: "POST",
                    url: "/api/sitecore/Accounts/VerifyOTP",
                    data: {
                        OTP: value
                    },
                    success: function (d) {
                        if (!!d) {
                            var dialog = $(self.otpSelector).find("dialog").get(0);
                            dialog.close();
                            clearTimeout(self.resendLinkTO)
                        } else {
                            // Wrong OTP
                            $(self.errorSelector).show();
                            self.showResendLink();
                        }
                    },
                    error: function (er) {
                        console.log(er);
                    }
                });
            }
        })
        // Fill all the boxes on Paste
        // Add error message on Backend and show when invalid OTP

        var cookies = self.parseCookie(document.cookie)
        if (cookies["ValidateActiveUser"] != undefined && cookies["ValidateActiveUser"]["OTPVerified"] != "True") {
            var dialog = $(self.otpSelector).find("dialog").get(0);

            dialog.showModal();
        }

        self.resendLinkTO = setTimeout(function () { self.showResendLink(); }, 5000);
    },
    showResendLink: function () {
        var self = this;
        $(self.otpSelector).find(".a-link").show();
    }
});



/**
 * Questions for Business
 * 1. Email format is fine?
 * 2. When to show Resend OTP link?
 * 3. Text on Popup is fine?
 *
 */