let observeDOM = (function () {
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
    return function( obj, callback ) {
        if( !obj || obj.nodeType !== 1 ) return;

        if( MutationObserver ) {
            var obs = new MutationObserver(function(mutations, observer) {
                callback(mutations);
            })
            obs.observe( obj, { childList:true, subtree:true });
        } else if( window.addEventListener ) {
            obj.addEventListener('DOMNodeInserted', callback, false);
            obj.addEventListener('DOMNodeRemoved', callback, false);
        }
    }
})();

export default observeDOM;