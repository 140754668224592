window.onload = function () {
	var hash = window.location.hash;
	if ($(hash).length > 0) {
		$('html, body').animate({
			scrollTop: $(hash).offset().top - ($('.f-header').height() || 0)
		}, 800);
	}
}

$(document).on('click', "a", function (event) {
	var s = this;

	if(s.hostname.toLowerCase() === window.location.hostname.toLowerCase()) {
		if (this.hash !== "") {
			if ($('.f-modals ' + this.hash).length > 0) {
				$('.f-modals ' + this.hash).trigger("openmodal");
			} else if ($(s.hash).length > 0) {
				$('html, body').animate({
					scrollTop: $(s.hash).offset().top - ($('.f-header').height() || 0)
				}, 800, function () {
					$(s.hash).focus();
				});
			}
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
	}
});
export default  {};