import { Component } from "fq-foundation/Scripts/Component";

new Component().create("Accordion", {
	element: ".f-accordion",
	init: function () {
		var self = this;
		if (isIE()) {
			$(self.element).each(function () {
				$(this).find('details').on('click', function () {
					if ($(this).attr('open') != undefined) {
						$(this).removeAttr('open');
					} else {
						$(this).attr('open', "true");
					}
				})
			});
		}
	}
});
export default Component;