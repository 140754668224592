
import { Component } from "fq-foundation/Scripts/Component"
new Component().create("ForErrorClass", {

    element: ".f-form",

    errorClass: ".validation-summary-errors",

    init: function () {

        $(this.element).closest("form").find(this.errorClass + " li").each(function () {

            $(this).html($(this).text())

        });

    }

});