import { Component } from "fq-foundation/Scripts/Component"
import "fq-feature/SmartyStreets/src/Scripts/main";

new Component().create("Smartystreets", {
    element: "[data-sc-field-name='street_address']",
    city: '[data-sc-field-name="city"]',
    state: '[data-sc-field-name="state"]',
    zip: '[data-sc-field-name="zip"]',
    beforeSetup: function (callback) {
        var self = this;
        $(self.city).attr("disabled", "disabled").attr("readonly", "readonly").addClass("is-hidden").parent().addClass("is-hidden");
        $(self.state).attr("disabled", "disabled").attr("readonly", "readonly").addClass("is-hidden").parent().addClass("is-hidden");
        callback();
    },
    keydown: function (e, el) {
        var self = this;
        if ((e.keyCode == 38 || e.keyCode == 40) && $(el).next().is(":visible")) {
            var highlighted = $(el).next().find('li.highlighted');
            if ($(highlighted).length > 0) {
                if (e.keyCode == 40) {
                    var next = $(highlighted).removeClass("highlighted").next().length > 0 ? $(highlighted).removeClass("highlighted").next() : $(el).next().find('li:eq(0)');
                    $(next).addClass("highlighted").trigger("awesomeplete-customhighlight");
                } else if (e.keyCode == 38) {
                    var prev = $(highlighted).removeClass("highlighted").prev().length > 0 ? $(highlighted).removeClass("highlighted").prev() : $(el).next().find('li:last');
                    $(prev).addClass("highlighted").trigger("awesomeplete-customhighlight");
                }
            } else {
                $(el).next().find('li:eq(0)').addClass("highlighted").trigger("awesomeplete-customhighlight");
            }
        } else {
            if ($(el).val().length < 5) {
                $(el).removeData('selected');
            }
            self.search(el);
        }
    },
    blur: function (e, el) {
        var self = this;
        if ($(el).data("flag") !== $(el).val()) {
            $(el).val("").removeData("selected");
            $(el).closest('form').find(self.state).val("").addClass("is-hidden").parent().addClass("is-hidden");
            $(el).closest('form').find(self.city).val("").addClass("is-hidden").parent().addClass("is-hidden");
            e.preventDefault();
        }
    },
    // submit: function (event, formElement) {
    //     var self = this;
    //     $(formElement).find(self.city).removeAttr("disabled");
    //     $(formElement).find(self.state).removeAttr("disabled");
    //     return true;
    // },
    selectComplete: function (e, el) {
        var self = this;
        var dummy = $("<div/>").html($(el).val());
        var selected = $(dummy).text();
        var data = $(el).data("list");
        var f = data.suggestions.filter(function (x) {
            return selected == x.street_line + ", " + x.secondary + " (" + x.entries + " More Entries), " + x.city + ", " + x.state + ", " + x.zipcode;
        })[0];
        var selectedData = data.suggestions.filter(function (x) {
            return selected == x.street_line + ", " + (x.secondary != "" ? (x.secondary + ", ") : "") + x.city + ", " + x.state + ", " + x.zipcode || selected == x.street_line + ", " + x.secondary + " (" + x.entries + " More Entries), " + x.city + ", " + x.state + ", " + x.zipcode;
        })[0];
        $(el).closest("form").find(".smartystreets__flag").val(selectedData.street_line + (selectedData.secondary != "" ? "," + selectedData.secondary : ""));
        $(el).data("flag", selectedData.street_line + (selectedData.secondary != "" ? ", " + selectedData.secondary : ""));
        $(el).val(selectedData.street_line + (selectedData.secondary != "" ? ", " + selectedData.secondary : ""));
        if (!!f) {
            selected = selected.replace(" More Entries), ", "), ");
            $(el).data("selected", selected);
            $(el).focus();
        } else {
            $(el).parent().next().focus();
        }
        $(el).closest('form').find(self.state).removeClass("is-hidden").val(selectedData.state).parent().removeClass("is-hidden");
        $(el).closest('form').find(self.city).val(selectedData.city).removeClass("is-hidden").parent().removeClass("is-hidden");
    }
});