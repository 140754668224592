import { Component } from "fq-foundation/Scripts/Component"
new Component().create("Reviews", {
    element: ".f-reviews",
    averageRatingContainer: ".m-star-rating",
    pagenumber: 0,
    init: function () {
        var self = this;
        $(self.element).find('.review-write form input[name=rating]').removeAttr("value");
        this.loadReviewsScript(function () {
            $(self.element).each(function () {
                var reviewEl = $(self.element);
                var wrapper = $("<div class=\"review-wrapper\"/>");
                var showMore = $("<a class=\"a-link show-more\">Show More Reviews</a>");
                var writeAReview = $("<a class=\"a-link write-review\">Write A Review</a>");
                $("#review-list").append(wrapper);
                $(wrapper).after(writeAReview);
                $(wrapper).after(showMore);
                $(writeAReview).on('click', function () {
                    $("html, body").css({
                        overflowY: "hidden"
                    });
                    $(".review-write").show();
                });
                $(".review-write .fa-times").on('click', function () {
                    $(".review-write").hide();
                    $("html, body").css({
                        overflowY: "auto"
                    });
                });
                $(".write-success .fa-times").on('click', function () {
                    $(".write-success").hide();
                });
                $(showMore).on('click', function () {
                    self.pagenumber += 5;
                    self.displayReview($(reviewEl));
                    $(showMore).off("click").hide();
                });
                self.displayReview($(reviewEl));
                self.createWriteForm($(reviewEl));
            });
        });
    },
    getProductInformation: function (data, callback) {
        $.ajax({
            url: "https://writeservices.powerreviews.com/war/writereview",
            method: "GET",
            data: {
                merchant_id: data.merchantId,
                page_id: data.pageId,
                locale: data.locale,
                includeLocalizations: true
            },
            success: function (data) {
                callback(data);
            },
            error: function (error) {
                throw error;
            }
        });
    },
    createWriteForm: function (element) {
        var self = this;
        var data = self.deSerializeObj($(element).find(".f-review__form").serialize());
        $('.review-write').find('input[required], textarea[required]').each(function () {
            $(this).prev().addClass("required")
        });
        if (data.pageId != "" && data.apikey !== "" && data.merchantId != "") {
            self.getProductInformation(data, function (prodInfo) {
                var productInfo = prodInfo.product_information;
                var contextInformation = prodInfo.context_information;
                var form = $(self.element).find('.review-write form');
                $(form).find(".write-review-title").html("Write a Review");
                $(form).find(".write-review-product-title").html(productInfo.name);
                $(form).find(".write-review-product-link").attr("href", productInfo.full_product_url)
                $(form).find(".rating .stars input[type=radio]").on("change", function () {
                    $(this).closest('.stars').find("[name=rating]").attr("value", $(this).closest('.stars').find('[name=write-star]:checked').index() + 1);
                });
                $(form).find('.review-section [name=headline]')
                $(form).on('submit', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    var frmdata = self.deSerializeObj($(this).serialize());
                    var fields = [];
                    for (var key in frmdata) {
                        if (key == "rating" || key == "headline" || key == "comments" || key == "name" || key == "location") {
                            var field = {
                                "field_type": "simple",
                                "key": key,
                                "value": key == "rating" ? Number(frmdata[key]) : decodeURI(frmdata[key])
                            }
                            fields.push(field);
                        }
                    }
                    if (frmdata.rating == "") {
                        var div = $("<div />");
                        $(div).css({
                            position: "absolute",
                            bottom: "-1em",
                            left: 0,
                            color: "red",
                            opacity: 0
                        }).html("Please select your rating");
                        $(form).find("input[name=rating]").closest(".rating").append(div);
                        $(div).animate({ opacity: 1 }, 800, function () {
                            setTimeout(function () {
                                $(div).animate({ opacity: 1 }, 800, function () {
                                    $(div).remove()
                                });
                            }, 10000);
                        });
                        return false;
                    }
                    self.postToPowerReviews(data, fields, contextInformation, prodInfo, form)
                    return false;
                    // POST to Power Reviews
                });
            });
        } else {

        }
    },
    postToPowerReviews: function (data, fields, context, prodInfo, form) {
        var self = this;
        var url = "";
        // if (data.env === "dev") {
        //     if (data.fail === "true") {
        //         url = "/api/reviews/invalid"
        //     } else {
        // url = "/api/reviews"
        //     }
        // } else {
        url = "https://writeservices.powerreviews.com/war/writereview?merchant_id=234090&page_id=AIR-014&is_complete=true&locale=en_US"
        // }
        $.ajax({
            url: url,
            method: "POST",
            contentType: "application/json",
            data: JSON.stringify({
                context_information: context,
                fields: fields
            }),
            success: function (a) {
                $(".review-write").hide();
                $("html, body").css({
                    overflowY: "auto"
                });
                $(form).trigger('reset');
                self.showThankyou(a, prodInfo);
            },
            error: function (er, d) {
                var er_message = er.responseJSON.message;
                $.each(er.responseJSON.fields, function () {
                    if (!!this.error_message) {
                        var el = $(self.element).find('.review-write form').find('[name=' + this.key + ']');
                        var div = $("<div />")
                        $(div).css({
                            color: "red",
                            opacity: 0
                        }).html(er_message);
                        if (this.key === "rating") {
                            $(el).parent().before(div);
                        } else {
                            $(el).before(div);
                        }
                        $(div).animate({ opacity: 1 }, 800, function () {
                            setTimeout(function () {
                                $(div).animate({ opacity: 1 }, 800, function () {
                                    $(div).remove()
                                    $(el).removeAttr("style");
                                });
                            }, 10000);
                        });
                    }
                });
            }
        });
    },
    showThankyou: function (data, productInfo) {
        var self = this;
        var writeSuccessPopup = $(self.element).find(".write-success");
        $(writeSuccessPopup).find(".success-title .product-link").attr("href", productInfo.product_information.full_product_url);
        var rating = data.fields.filter(function (i) {
            return i.key == "rating";
        })[0].value;
        var headline = data.fields.filter(function (i) {
            return i.key == "headline";
        })[0].value;
        var comments = data.fields.filter(function (i) {
            return i.key == "comments";
        })[0].value;
        var date = new Date(data.context_information.review_start_date).toLocaleDateString("en-us", { year: "numeric", month: "numeric", day: "numeric" });
        $(writeSuccessPopup).find('.write-success-review-data .m-star-rating').attr("data-rating", rating)
        $(writeSuccessPopup).find('.write-success-review-data .write-success-star-value').html(rating + ".0");
        $(writeSuccessPopup).find('.write-success-review-data .write-success-rating-date').html(date.toString());
        $(writeSuccessPopup).find('.write-success-review-data .write-success-review-texts .a-title').html(decodeURIComponent(headline));
        $(writeSuccessPopup).find('.write-success-review-data .write-success-review-texts .a-description').html(decodeURIComponent(comments));
        $(writeSuccessPopup).show();
        if (isIE()) {
            var starElement = $(self.element).find('.write-success-review-data .m-star-rating')
            var numberOfStars = rating, decimalValue = 0;

            for (var i = 0; i < numberOfStars; i++) {
                $(starElement).find('svg .active rect:eq(' + i + ')').attr('width', 100);
            }
            $(starElement).find('svg .active rect:eq(' + numberOfStars + ')').attr('width', (decimalValue * 10));
        }
    },
    displayReview: function (element) {
        var self = this;
        var data = self.deSerializeObj($(element).find(".f-review__form").serialize());
        if (data.pageId != "" && data.apikey !== "" && data.merchantId != "") {
            data = Object.assign(data, {
                "paging.from": self.pagenumber,
                "paging.size": 5,
                filters: {},
                search: {},
                sort: "HighestRating",
                "image_only": false
            });
            $.ajax({
                url: "https://display.powerreviews.com/m/" + data.merchantId + "/l/" + data.locale + "/product/" + data.pageId + "/reviews",
                data: data,
                crossDomain: true,
                success: function (data) {
                    var results = data.results[0];
                    var wrapper = $("#review-list").find(".review-wrapper");
                    for (var i = 0; i < results.reviews.length; i++) {
                        var review = results.reviews[i];
                        var item = $("<div class=\"review-item\"/>");
                        $(wrapper).append(item);
                        var author = $("<div class=\"review-author\"/>");
                        $(author).append(review.details.nickname);
                        $(item).append(author);
                        var headline = $("<div class=\"review-headline\"/>");
                        $(headline).append(review.details.headline);
                        $(item).append(headline);
                        var starTemplate = '<div class="m-star-rating" data-rating="' + review.metrics.rating + '"><div class="m-star-rating__wrapper"><svg version="1.1" shape-rendering="geometricPrecision" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" xml:space="preserve"><defs><clipPath id="clip-star"><polygon x="0" y="0" points="72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 " /></clipPath></defs><g class="non-active"><path d="M 72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 Z"></path><path d="M 72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 Z"></path><path d="M 72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 Z"></path><path d="M 72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 Z"></path><path d="M 72,62.7 99.2,38.4 63.6,35.5 50,0.8 36.4,35.5 0.8,38.3 28,62.7 19.5,99.2 49.6,79.5 50,79.5 80.3,99.2 Z"></path></g><g class="active"><rect x=0 y=0 width=0 height=100 /><rect x=0 y=0 width=0 height=100 /><rect x=0 y=0 width=0 height=100 /><rect x=0 y=0 width=0 height=100 /><rect x=0 y=0 width=0 height=100 /></g></svg>';
                        $(item).append(starTemplate);
                        var comment = $("<div class=\"review-comment\"/>");
                        $(comment).append(review.details.comments);
                        $(item).append(comment);
                        if (self.pagenumber == 0) {
                            $(self.element).prev(self.averageRatingContainer).attr('data-rating', results.rollup.average_rating.toFixed(1))
                        }
                    }
                    window._componentList["Ratings"].init();
                },
                error: function (error) {
                    $(self.element).hide();
                    console.log(error);
                }
            });
        } else {
            $(self.element).hide();
        }
    },
    loadReviewsScript: function (callback) {
        var self = this;
        if (!window['POWERREVIEWS']) {
            $.getScript("//ui.powerreviews.com/stable/4.0/ui.js")
                .done(function (script, textStatus) {
                    callback();
                })
                .fail(function (jqxhr, settings, exception) {
                    throw 'Power Reviews could not be loaded';
                    callback();
                });
        }
    },
    deSerializeObj: function (serialized) {
        var d = {}, self = this;
        var a = serialized.split("&");
        for (var i = 0; i < a.length; i++) {
            var o = a[i].split("=");
            if (d[o[0]] == undefined) {
                d[o[0]] = o[1];
            } else {
                if (o[0] !== o[1]) {
                    var prev = d[o[0]];
                    d[o[0]] = [];
                    d[o[0]].push(prev);
                    d[o[0]].push(o[1]);
                }
            }
        }
        return d;
    }
});
