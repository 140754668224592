// Usable in both EE and regular mode hence tabs.ee.js simply imports it
import { Component } from "fq-foundation/Scripts/Component"
new Component().create("Tabs", {
    element: ".a-tabs",
    tabsHeads: "ul>li",
    activeTabHead: "ul>li.is-active",
    tabContent: ".tab-content",
    init: function () {
        var self = this;
        observeDOM(document.body, function (m) {
            self.initTabs();
        });
        self.initTabs();
    },
    initTabs: function () {
        var self = this;
        $(self.element).each(function () {
            $(self.tabsHeads).off('click').on('click', function () {
                $(this).closest(self.element).find(self.tabsHeads).removeClass('is-active');
                $(this).addClass('is-active').trigger('change');
            });
            $(self.tabsHeads).off('change').on('change', function () {
                var dContentArea = $(this).closest(self.element).find(self.activeTabHead).data('key');
                $(this).closest(self.element).find(self.tabContent).addClass('is-hidden');
                $(this).closest(self.element).find(self.tabContent + '[data-key="' + dContentArea + '"]').removeClass('is-hidden');
            });
            $(this).find(self.tabsHeads + ":eq(0)").trigger('change');
        });
    }
});