import { Component } from "fq-foundation/Scripts/Component"

new Component().create("Header", {
    element: ".f-header",
    init: function () {
        var self = this;
        $(".f-header__topbar .f-account--login .loginLink").attr("href", "#fedAuthCP--Desktop");
        $(".f-header__topbar .f-account--login .f-modals .fedAuthCP-modal").attr("id", "fedAuthCP--Desktop");

        $(".f-header__bottombar .f-account--login .loginLink").attr("href", "#fedAuthCP--Mobile");
        $(".f-header__bottombar .f-account--login .f-modals .fedAuthCP-modal").attr("id", "fedAuthCP--Mobile");

        $(".sub-menu-checkbox").each(function () {
            var id = $(this).attr("id");
            $(this).find("+label+.sub-menu .back-to-main-menu").each(function () {
                $(this).attr("for", id);
            });
        });
        try {
            document.createEvent("TouchEvent");
            $(".sub-menu-checkbox").on('change', function () {
                if ($(this).prop("checked")) {
                    $(".sub-menu-checkbox:checked + label + .sub-menu").offset({
                        top: $(this).closest(".f-header__nav-wrap").parent().prev().height()
                    });
                }
            });
        } catch (e) {
            // 1280px
            $(self.element).find('.main-menu-item.has-submenu').on('mouseover', function () {
                if (window.innerWidth > 1279) {
                    $(this).find(".sub-menu-checkbox").prop("checked", true);
                }
            });
            $(self.element).find('.main-menu-item.has-submenu').on('mouseout', function () {
                if (window.innerWidth > 1279) {
                    $(this).find(".sub-menu-checkbox").prop("checked", false);
                }
            });
        }
        $(".sub-menu-checkbox-label").on('click', function () {
            if (!$("#" + $(this).attr('for')).prop('checked')) {
                $('.sub-menu-checkbox').each(function () {
                    $(this).prop('checked', false);
                    $("#" + $(this).attr('for')).prop('checked', true);
                });
            }
        });

    }
});